import * as React from 'react'

import { getDatabase } from './firebase'
import * as database from 'firebase/database'

import { IRootState, PerformanceState } from './reducers/types'
import { useDispatch, useSelector } from 'react-redux'
import { createUpdateKeyAction } from './reducers/actions'

export function useActiveUsers() {
	const [activeUsers, setActiveUsers] = React.useState(0)
	const ref = useDomainNameRef()
	const activeUsersRef = database.child(ref, 'activeUsers')
	React.useEffect(() => {
		const unsubscribe = database.onValue(activeUsersRef, (snapshot) => {
			setActiveUsers(snapshot && snapshot.exists() ? snapshot.size : 0)
		})
		return () => unsubscribe()
	})
	return activeUsers
}

export function useDomainNameRef() {
	const domainName = useSelector((s: IRootState) => s.domainName)
	const rootRef = database.ref(getDatabase())
	return domainName === 'global'
		? rootRef
		: database.child(rootRef, domainName)
}

export function useDatabaseStateRef() {
	return database.child(useDomainNameRef(), 'state')
}

function propertiesStringToEnum(state: string) {
	switch (state) {
		case 'waiting':
			return PerformanceState.WAITING
		case 'started':
			return PerformanceState.PLAYING
		case 'ended':
			return PerformanceState.FINISHED
		default:
			return PerformanceState.NONE
	}
}

export function usePerformanceState() {
	const [state, setState] = React.useState(PerformanceState.NONE)
	const { countdownTime, clockOffset } = useSelector((s: IRootState) => s)
	const stateRef = useDatabaseStateRef()

	React.useEffect(() => {
		const unsubscribe = database.onValue(stateRef, (snapshot) => {
			if (snapshot) {
				const val = snapshot.val()
				if (!val || val.state === undefined) {
					database.update(stateRef, { state: 'waiting' })
					setState(PerformanceState.NONE)
				} else {
					setState(propertiesStringToEnum(val.state))
				}
			}
		})
		return () => unsubscribe()
	}, [stateRef])

	const setter = React.useCallback(
		(state: PerformanceState) => {
			switch (state) {
				case PerformanceState.WAITING:
					database.update(stateRef, {
						startTime: 0,
						state: 'waiting'
					})
					break
				case PerformanceState.PLAYING: {
					const startTime = Date.now() + clockOffset + countdownTime
					// const timeOfClosestBar = timeOfFirstBeatClosestAtTime(startTime)
					database.update(stateRef, { startTime, state: 'started' })
					break
				}
				case PerformanceState.FINISHED:
					database.update(stateRef, { startTime: 0, state: 'ended' })
					break
				default:
					break
			}
		},
		[countdownTime, clockOffset, stateRef]
	)

	return [state, setter] as [
		PerformanceState,
		(state: PerformanceState) => void
	]
}

export function usePerformanceStartTime() {
	const [state, setState] = React.useState(0)
	const stateRef = useDatabaseStateRef()

	React.useEffect(() => {
		const unsubscribe = database.onValue(stateRef, (snapshot) => {
			if (snapshot) {
				const val = snapshot.val()
				if (!val || val.startTime === undefined) {
					database.update(stateRef, { startTime: 0 })
					setState(0)
				} else {
					setState(val.startTime)
				}
			}
		})
		return () => unsubscribe()
	}, [stateRef])

	const setter = React.useCallback(
		(startTime: number) => {
			database.update(stateRef, { startTime, state: 'started' })
		},
		[stateRef]
	)

	return [state, setter] as [number, (state: number) => void]
}

export function useRefresh() {
	const rootRef = useDomainNameRef()
	const refresh = React.useCallback(() => {
		const refreshRef = database.child(rootRef, 'refresh')
		database.set(refreshRef, Date.now())
	}, [rootRef])
	return refresh
}

export function useAvailableDomainNames() {
	const [state, setState] = React.useState<string[]>([])
	React.useEffect(() => {
		const rootRef = database.ref(getDatabase())
		database.get(rootRef).then((snapshot) => {
			const children = ['global']
			const excluded = ['state', 'refresh', 'activeUsers']
			snapshot.forEach((child) => {
				const key = child.key || 'global'
				if (
					children.indexOf(key) === -1 &&
					excluded.indexOf(key) === -1
				) {
					children.push(key)
				}
			})
			setState(children)
		})
	}, [])
	return [state] as [string[]]
}

export function useRootStateKey<K extends keyof IRootState>(key: K) {
	const dispatch = useDispatch()
	const state = useSelector((s: IRootState) => s[key])
	const setState = React.useCallback(
		(value: IRootState[K]) => {
			dispatch(createUpdateKeyAction(key, value))
		},
		[key, dispatch]
	)
	return [state, setState] as [IRootState[K], (value: IRootState[K]) => void]
}
