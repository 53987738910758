import { initializeApp } from 'firebase/app'
import * as database from 'firebase/database'
import * as functions from 'firebase/functions'
import * as auth from 'firebase/auth'

const app = initializeApp({
	apiKey: 'AIzaSyC3y2cPL333KvSbcBuV9faTyClmjDjm2gg',
	authDomain: 'smartphone-orchestra.firebaseapp.com',
	databaseURL: 'https://smartphone-orchestra.firebaseio.com',
	projectId: 'smartphone-orchestra',
	storageBucket: 'smartphone-orchestra.appspot.com',
	messagingSenderId: '78165283269',
	appId: '1:78165283269:web:99369be32e25c750e73bac'
})

export function signOut() {
	auth.getAuth(app).signOut()
}

export function currentUser() {
	return auth.getAuth(app).currentUser
}

export function signInWithEmailAndPassword(username: string, password: string) {
	return auth.signInWithEmailAndPassword(
		auth.getAuth(app),
		username,
		password
	)
}

export function onAuthStateChanged(
	nextOrObserver: auth.NextOrObserver<auth.User>
) {
	return auth.onAuthStateChanged(auth.getAuth(app), nextOrObserver)
}

export function httpsCallable(name: string) {
	return functions.httpsCallable(functions.getFunctions(app), name)
}

export function getDatabase() {
	return database.getDatabase(app)
}

// function timeOfFirstBeatClosestAtTime(time: number) {
// 	const beatsPassed = time / (60000 / 120)
// 	const phase = beatsPassed % 4
// 	const beatsToGo = 4 - phase
// 	const timeExtra = beatsToGo * (60000 / 120)
// 	return time + timeExtra
// }
