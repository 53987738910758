import * as React from 'react'
import { getCorrectedClock } from './WebSync'

export const useTimePassed = (startTime: number) => {
	const [time, setTime] = React.useState(0)
	React.useEffect(() => {
		const interval = setInterval(
			() => setTime(Math.floor((getCorrectedClock() - startTime) / 1000)),
			100
		)
		return () => clearInterval(interval)
	}, [startTime])
	return [time]
}
