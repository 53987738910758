import React from 'react'
import { User } from 'firebase/auth'
import { signInWithEmailAndPassword, onAuthStateChanged } from './firebase'
import { Form, Button, Container, Message } from 'semantic-ui-react'
import App from './App'

const LoginForm = () => {
	const [username, setUsername] = React.useState<string>('')
	const [password, setPassword] = React.useState<string>('')
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [error, setError] = React.useState<any>(null)

	const onSubmit = (e: React.FormEvent) => {
		e.preventDefault()
		signInWithEmailAndPassword(username, password).catch(setError)
	}

	return (
		<Container>
			{error && (
				<Message negative>
					<Message.Header>Login failed</Message.Header>
					<p>{error.message}</p>
				</Message>
			)}
			<Form onSubmit={onSubmit}>
				<Form.Field>
					<label>Email address</label>
					<input
						value={username}
						onChange={(e) => setUsername(e.target.value)}
					/>
				</Form.Field>
				<Form.Field>
					<label>Password</label>
					<input
						type="password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					/>
				</Form.Field>
				<Button type="submit">Login</Button>
			</Form>
		</Container>
	)
}

const LoginGate = () => {
	const [user, setUser] = React.useState<User | null>(null)

	React.useEffect(() => {
		const unregister = onAuthStateChanged(setUser)
		return () => unregister()
	}, [setUser])

	if (!user) {
		return <LoginForm />
	}
	return <App />
}

export default LoginGate
