let offset = 0

export interface IListener {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[type: string]: Array<(details: any) => void>
}

export default function (callback: (offset: number) => void) {
	const server = 'wss://spo-metronome.appspot.com/'
	const connection = new WebSocket(server, 'echo-protocol')
	connection.onmessage = (e: MessageEvent) => {
		const { type, ...data } = JSON.parse(e.data)
		if (type === 'ping') {
			const { serverTime } = data
			const reply = { type: 'pong', clientTime: Date.now(), serverTime }
			connection.send(JSON.stringify(reply))
		} else if (type === 'offset') {
			callback(data.offset)
			offset = data.offset
		}
	}
}

export function getCorrectedClock() {
	return Date.now() + offset
}
