import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	Button,
	Container,
	Dimmer,
	Grid,
	Header,
	Loader,
	Segment
} from 'semantic-ui-react'
import CountdownTime from './components/CountdownTime'
import Timeline from './components/Timeline'
import CompositionPicker from './containers/CompositionPicker'
import PerformanceStateButtons from './containers/PerformanceStateButtons'
import Stats from './containers/Stats'
import { IRootState, SyncState } from './reducers/types'
import {
	createSetCountdownTimeAction,
	createSyncEstablishedAction
} from './reducers/actions'
import { currentUser, signOut } from './firebase'
import { usePerformanceStartTime, useRefresh } from './hooks'
import DomainPicker from './containers/DomainPicker'
import WebSync from './WebSync'

const App = () => {
	const dispatch = useDispatch()
	const [startTime, setStartTime] = usePerformanceStartTime()
	const { countdownTime, syncState } = useSelector((s: IRootState) => s)
	const refresh = useRefresh()

	React.useEffect(() => {
		setTimeout(() => {
			WebSync((o) => {
				dispatch(createSyncEstablishedAction(o))
			})
		}, 10)
	}, [dispatch])

	return (
		<Container>
			<Header as="h1" icon textAlign="center">
				<Header.Content>- Smartphone Orchestra -</Header.Content>
			</Header>

			<Grid stackable>
				<Grid.Row columns={1}>
					<Grid.Column>
						<PerformanceStateButtons />
					</Grid.Column>
				</Grid.Row>

				<Grid.Row columns={3}>
					<Grid.Column>
						<Segment textAlign="center">
							<Stats />
						</Segment>
						<Segment textAlign="center">
							<Button
								fluid
								tabIndex={0}
								key="refreshDevices"
								color="red"
								content="Refresh Connected Devices"
								icon="refresh"
								onClick={() => refresh()}
							/>
						</Segment>
						<Segment textAlign="center">
							<p>{currentUser()?.email || ''}</p>
							<Button basic onClick={() => signOut()}>
								Sign-out
							</Button>
						</Segment>
					</Grid.Column>
					<Grid.Column>
						<Segment textAlign="center">
							<Timeline
								startTime={startTime}
								setStartTime={(time) => setStartTime(time)}
							/>
						</Segment>
						<Segment textAlign="center">
							<CountdownTime
								countdownTime={countdownTime}
								setCountdownTime={(time) =>
									dispatch(createSetCountdownTimeAction(time))
								}
							/>
						</Segment>
					</Grid.Column>
					<Grid.Column>
						<DomainPicker />
						<CompositionPicker />
					</Grid.Column>
				</Grid.Row>
			</Grid>

			<Dimmer active={syncState !== SyncState.CONNECTED} page>
				<Loader size="massive" content="Connecting..." />
			</Dimmer>
		</Container>
	)
}

export default App
