import * as React from 'react'
import {
	Dropdown,
	DropdownItemProps,
	Header,
	List,
	Segment
} from 'semantic-ui-react'
import { useAvailableDomainNames, useRootStateKey } from '../hooks'

const DomainPicker = () => {
	const [availableDomains] = useAvailableDomainNames()
	const [domainName, setDomainName] = useRootStateKey('domainName')

	const content: DropdownItemProps[] = availableDomains.map((domainName) => ({
		value: domainName,
		text: domainName
	}))

	return (
		<Segment textAlign="center">
			<Header>Current Domain: {domainName}</Header>
			<List>
				<List.Item>
					<Dropdown
						fluid
						selection
						search
						defaultValue={domainName}
						placeholder="No domain name Selected"
						options={content}
						onChange={(_, data) =>
							setDomainName(data.value as string)
						}
					/>
				</List.Item>
			</List>
		</Segment>
	)
}

export default DomainPicker
