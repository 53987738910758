import * as React from 'react'
import { Label } from 'semantic-ui-react'
import { getCorrectedClock } from '../WebSync'
import { useTimePassed } from '../utils'
import TimelineNumber from './TimelineNumber'

interface ITimelineProps {
	startTime: number
	setStartTime: (startTime: number) => void
}

const Timeline = (props: ITimelineProps) => {
	const [timePlaying] = useTimePassed(props.startTime)

	const handleManualChange = (targetTimeSinceStart) => {
		const playingTime = getCorrectedClock() - props.startTime
		const difference = targetTimeSinceStart * 1000 - playingTime
		const targetStartTime = props.startTime - difference
		props.setStartTime(targetStartTime)
	}

	if (props.startTime === 0) {
		return <Label size="massive">Not playing...</Label>
	}

	return <TimelineNumber value={timePlaying} onChange={handleManualChange} />
}

export default Timeline
