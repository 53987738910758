import * as React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { configureStore } from '@reduxjs/toolkit'
import 'semantic-ui-css/semantic.min.css'
import LoginGate from './LoginGate'
import reducer from './reducers'

const store = configureStore({ reducer })

// eslint-disable-next-line
const container = document.getElementById('root')!
const root = createRoot(container)
root.render(
	<Provider store={store}>
		<LoginGate />
	</Provider>
)

document.body.classList.remove('loading')
