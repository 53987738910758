import * as React from 'react'
import { Statistic } from 'semantic-ui-react'
import { useActiveUsers } from '../hooks'

const Stats = () => {
	const activeUsers = useActiveUsers()
	return <Statistic label="Connections" value={activeUsers} />
}

export default Stats
