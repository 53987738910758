import { Action, ActionTypes } from './actions'
import { IRootState, RequestState, SyncState } from './types'

const initialState: IRootState = {
	countdownTime: 10000,
	liveSets: [],
	currentComposition: null,
	request: null,
	syncState: SyncState.NOT_CONNECTED,
	clockOffset: 0,
	domainName: 'global'
}

export default function (state = initialState, action: Action): IRootState {
	switch (action.type) {
		case ActionTypes.SET_COUNTDOWN_TIME:
			return { ...state, countdownTime: action.countdownTime }

		case ActionTypes.COMPOSITION_REQUEST: {
			return { ...state, request: 'active' as RequestState }
		}

		case ActionTypes.COMPOSITION_REQUEST_FAILED: {
			return { ...state, request: 'failed' as RequestState }
		}

		case ActionTypes.COMPOSITION_REQUEST_SUCCESS: {
			return { ...state, request: 'success' as RequestState }
		}

		case ActionTypes.COMPOSITIONS_CHANGED: {
			const { liveSets } = action
			return { ...state, liveSets }
		}

		case ActionTypes.CURRENT_COMPOSITION_CHANGED: {
			const { currentComposition } = action
			return { ...state, currentComposition }
		}

		case ActionTypes.SYNC_ESTABLISHED: {
			const clockOffset = action.clockOffset
			return { ...state, syncState: SyncState.CONNECTED, clockOffset }
		}

		case ActionTypes.SYNC_DISCONNECTED: {
			return {
				...state,
				syncState: SyncState.NOT_CONNECTED,
				clockOffset: 0
			}
		}

		case ActionTypes.UPDATE_KEY: {
			const { key, value } = action
			return { ...state, [key]: value }
		}

		default:
			return state
	}
}
