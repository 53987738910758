import * as React from 'react'
import { Segment, Step } from 'semantic-ui-react'
import { usePerformanceState } from '../hooks'
import { PerformanceState } from '../reducers/types'

const stateButtons = [
	{ text: 'Waiting', icon: 'pause', state: PerformanceState.WAITING },
	{
		text: 'Countdown & Playing',
		icon: 'play',
		state: PerformanceState.PLAYING
	},
	{ text: 'Finished', icon: 'stop', state: PerformanceState.FINISHED }
]

const PerformanceStateButtons = () => {
	const [performanceState, setPerformanceState] = usePerformanceState()
	return (
		<Segment padded="very" textAlign="center" size="massive" color="green">
			<Step.Group>
				{stateButtons.map((btn) => (
					<Step
						key={btn.text}
						title={btn.text}
						icon={btn.icon}
						active={btn.state === performanceState}
						onClick={() => setPerformanceState(btn.state)}
					/>
				))}
			</Step.Group>
		</Segment>
	)
}

export default PerformanceStateButtons
