import type {} from 'redux-thunk/extend-redux'

import { httpsCallable } from '../firebase'
import { IRootState } from './types'

export enum ActionTypes {
	COMPOSITION_REQUEST = 'composition/COMPOSITION_REQUEST',
	COMPOSITION_REQUEST_FAILED = 'composition/COMPOSITION_REQUEST_FAILED',
	COMPOSITION_REQUEST_SUCCESS = 'composition/COMPOSITION_REQUEST_SUCCESS',
	COMPOSITIONS_CHANGED = 'composition/COMPOSITIONS_CHANGED',
	CURRENT_COMPOSITION_CHANGED = 'composition/CURRENT_COMPOSITION_CHANGED',
	SYNC_ESTABLISHED = 'SYNC_ESTABLISHED',
	SYNC_DISCONNECTED = 'SYNC_DISCONNECTED',
	SET_COUNTDOWN_TIME = 'SET_COUNTDOWN_TIME',
	UPDATE_KEY = 'UPDATE_KEY'
}

export function createAction<T extends { type: ActionTypes }>(d: T): T {
	return d
}

export function createUpdateKeyAction<K extends keyof IRootState>(
	key: K,
	value: IRootState[K]
) {
	return createAction({ type: ActionTypes.UPDATE_KEY, key, value })
}

export function createSetCountdownTimeAction(countdownTime: number) {
	return createAction({ type: ActionTypes.SET_COUNTDOWN_TIME, countdownTime })
}

export function createSyncEstablishedAction(clockOffset: number) {
	return createAction({ type: ActionTypes.SYNC_ESTABLISHED, clockOffset })
}

export function createSyncDisconnectedAction() {
	return createAction({ type: ActionTypes.SYNC_DISCONNECTED })
}

export function requestCompositionAction() {
	return createAction({ type: ActionTypes.COMPOSITION_REQUEST })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function compositionChangedAction(liveSets: any[]) {
	return createAction({ type: ActionTypes.COMPOSITIONS_CHANGED, liveSets })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function currentCompositionChangedAction(currentComposition: any) {
	return createAction({
		type: ActionTypes.CURRENT_COMPOSITION_CHANGED,
		currentComposition
	})
}

export function compositionRequestSuccessAction() {
	return createAction({ type: ActionTypes.COMPOSITION_REQUEST_SUCCESS })
}

export function compositionRequestFailedAction() {
	return createAction({ type: ActionTypes.COMPOSITION_REQUEST_FAILED })
}

export function fetchProjects() {
	return (dispatch) => {
		dispatch(requestCompositionAction())

		const currentCompositionRequest = httpsCallable(
			'getCurrentComposition'
		)().then((result) => {
			dispatch(currentCompositionChangedAction(result.data))
		})

		const compositionsRequest = httpsCallable('getLiveSets')().then(
			(result) => {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				dispatch(compositionChangedAction(result.data as any[]))
			}
		)

		Promise.all([compositionsRequest, currentCompositionRequest])
			.then(() => dispatch(compositionRequestSuccessAction()))
			.catch(() => dispatch(compositionRequestFailedAction()))
	}
}

export function updateCurrentComposition(id) {
	return (dispatch) => {
		dispatch(requestCompositionAction())
		httpsCallable('setCurrentComposition')({ id })
			.then(() => {
				dispatch(fetchProjects())
			})
			.catch(() => {
				dispatch(compositionRequestFailedAction())
			})
	}
}

export type Action = ReturnType<
	| typeof createUpdateKeyAction
	| typeof createSetCountdownTimeAction
	| typeof createSyncEstablishedAction
	| typeof createSyncDisconnectedAction
	| typeof requestCompositionAction
	| typeof compositionChangedAction
	| typeof currentCompositionChangedAction
	| typeof compositionRequestSuccessAction
	| typeof compositionRequestFailedAction
>
