import * as React from 'react'
import { Input } from 'semantic-ui-react'

interface ITimelineNumberProps {
	value: number
	onChange: (value: number) => void
}

function isValidValue(value: string) {
	if (value === '') {
		return false
	}
	const n = parseInt(value, 10)
	return !isNaN(n)
}

const TimelineNumber = (props: ITimelineNumberProps) => {
	const [isFocussed, setFocussed] = React.useState(false)
	const [currentValue, setValue] = React.useState('')

	React.useEffect(() => {
		if (isFocussed === false) {
			setValue(props.value.toString())
		}
	}, [props.value, isFocussed])

	const handleKeyUp = (e) => {
		if (e.keyCode === 13 && isValidValue(currentValue)) {
			props.onChange(parseInt(currentValue, 10))
			e.target.blur()
		}
	}

	return (
		<Input
			type="number"
			value={currentValue}
			size="massive"
			fluid
			error={!isValidValue(currentValue)}
			onFocus={() => setFocussed(true)}
			onBlur={() => setFocussed(false)}
			onKeyUp={handleKeyUp}
			onChange={(e) => setValue(e.currentTarget.value)}
		/>
	)
}

export default TimelineNumber
