import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Dropdown, Header, List, Segment } from 'semantic-ui-react'
import { IRootState } from '../reducers/types'
import { fetchProjects, updateCurrentComposition } from '../reducers/actions'

const CompositionPicker = () => {
	const [selectedLiveSet, setSelectedLiveSet] = React.useState('')
	const dispatch = useDispatch()
	const { currentComposition, liveSets, request } = useSelector(
		(s: IRootState) => s
	)

	React.useEffect(() => {
		dispatch(fetchProjects())
		// eslint-disable-next-line
	}, [])

	const selectedValue = currentComposition ? currentComposition._id : ''
	const name = currentComposition
		? currentComposition.name
		: 'No Current Composition'

	const content = liveSets.map((liveSet) => {
		return {
			value: liveSet._id,
			text: liveSet.name,
			description: liveSet.project.name
		}
	})

	return (
		<Segment textAlign="center" loading={request === 'active'}>
			<Header>Current Composition: {name}</Header>
			<List>
				<List.Item>
					<Dropdown
						fluid
						selection
						search
						defaultValue={selectedValue}
						placeholder="No Composition Selected"
						options={content}
						onChange={(_, data) =>
							setSelectedLiveSet(data.value as string)
						}
					/>
				</List.Item>

				<List.Item>
					<Button
						fluid
						content="Save"
						tabIndex={1}
						color="green"
						disabled={
							!selectedLiveSet ||
							selectedLiveSet === selectedValue
						}
						onClick={() =>
							dispatch(updateCurrentComposition(selectedLiveSet))
						}
					/>
				</List.Item>
			</List>
		</Segment>
	)
}

export default CompositionPicker
