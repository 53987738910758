import * as React from 'react'
import { Button } from 'semantic-ui-react'

interface ICountdownTimeProps {
	countdownTime: number
	setCountdownTime: (time: number) => void
}

const timeButtons = [
	{ text: '2s', time: 2000 },
	{ text: '10s', time: 10000 },
	{ text: '15s', time: 15000 }
]

const CountdownTime = (props: ICountdownTimeProps) => (
	<Button.Group>
		{timeButtons.map((btn) => (
			<Button
				key={btn.time}
				active={btn.time === props.countdownTime}
				onClick={() => props.setCountdownTime(btn.time)}
			>
				{btn.text}
			</Button>
		))}
	</Button.Group>
)

export default CountdownTime
