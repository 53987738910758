export interface IProject {
	_id: string
	name: string
}

export interface ILiveSet {
	_id: string
	name: string
	project: IProject
}

export type RequestState = 'active' | 'success' | 'failed'

export enum PerformanceState {
	NONE = -1,
	WAITING,
	PLAYING,
	FINISHED
}

export enum SyncState {
	NOT_CONNECTED,
	CONNECTING,
	CONNECTED
}

export interface IRootState {
	countdownTime: number
	liveSets: ILiveSet[]
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	currentComposition: any | null
	request: null | RequestState
	syncState: SyncState
	clockOffset: number
	domainName: string
}
